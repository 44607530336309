import React, {ReactNode} from 'react'
import {Button as MUIButton} from '@material-ui/core'

// 3rd-party
import classnames from 'classnames'
import type {ButtonProps as MUIButtonProps} from '@material-ui/core'
import {getSqaId} from 'services/testingServices'
import LoadingSpinner from 'components/LoadingSpinner'

export const variantMap = {
  primary: 'contained',
  secondary: 'outlined',
  tertiary: 'text',
  warning: 'contained'
} as const

export type ButtonProps = {
  className?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  isLoading?: boolean
  title: ReactNode | string
  variant?: keyof typeof variantMap
  size?: 'small' | 'medium' | 'large'
  startIcon?: ReactNode
  'data-sqa-id'?: string
  type?: MUIButtonProps['type']
  sqaPrefix?: string
  id?: string
}

const Button = ({
  className,
  variant = 'primary',
  isLoading = false,
  title,
  disabled,
  onClick,
  size = 'medium',
  sqaPrefix,
  ...rest
}: ButtonProps) => {
  return (
    <MUIButton
      className={classnames(`${variant}-button`, className)}
      disabled={disabled}
      onClick={onClick}
      variant={variantMap[variant]}
      // tertiary buttons don't support sizes other than medium
      size={variant === 'tertiary' ? 'medium' : size}
      // conditionally add props only in certain circumstances
      {...(variant === 'warning' ? {color: 'secondary'} : {})}
      {...getSqaId('button', sqaPrefix)}
      {...rest}
    >
      {isLoading ? <LoadingSpinner /> : title}
    </MUIButton>
  )
}

export default Button
