// DEV
const devCasAuthContent =
  'Basic NTc2OXAxNml1NjY0M2R2NXU0bWkydXFqODQ6MWQ3Ym9vaTQ3dThuMDZlMmdlMjBudTdxNWpkZDNyZjRsaDg2MzlxODFhZm1laGxsNHU4aw=='

// QA
const qaCasAuthContent =
  'Basic MjF1ZDI5ZnNjczVzNDg2Y2x1Zml1MGg5NzA6dHBpZXVuNDRuc2hiMGc3bjlwMTIxMGIzb3ZoYnBkOTRsMXFsYWd0cWc1MDIxN2Z1bDM0'

// INTEG
const integCasAuthContent =
  'Basic MW1rY2NhMXBpcXRxdjFtODNkcWNzbGp0YWw6bTJkMWNpZmt0NGIxNGNnNDVxMzhkNGZrc3JrMXR2N21ldXFvbzI3aXRybXM5bThvN2lw'

// PROD
const prodCasAuthContent =
  'Basic Mjc2ZnQ3YzZ2ZXNhdG5tc20xZ3BmMWYzNWQ6MXRibDB1cGU4Z20ycWFtdWVzcm1uMzN0cWhqcGR0ZHAwZjY5MGtrNzJuNTg4OXVzNzkxMw=='

export const authContentMap = {
  dev: devCasAuthContent,
  qa: qaCasAuthContent,
  integ: integCasAuthContent,
  production: prodCasAuthContent
} as const

// This is the list of URLs where we don't want to restrict access even if the
// user's access token is about to expire
export const WHITELISTED_ACCESS_TOKEN_ENDPOINTS = [
  'refresh-token',
  'sign-in-password',
  'sign-up',
  'sign-up-confirm',
  'answer-challenge',
  'forgot-password',
  'forgot-password/confirm',
  '/password',
  'social-auth-tokens',
  'sign-out'
]
