import React from 'react'
import styled from 'styled-components'
import HeroBackgroundCCLogo from 'assets/hero-background-cc-logo.png'
import AppHeader from 'components/AppHeader'
import Footer from 'components/Footer'
import ModalsSystem from 'components/ModalsSystem'
import {Route, Switch} from 'react-router-dom'

const ScheduledMaintenance = () => {
  return (
    <ScheduledMaintenance.Styled>
      <AppHeader isScheduledMaintenance />
      <Switch>
        <Route path='*'>
          <div className='maintenance-container'>
            <div className='content'>
              <h1 className='title'>Scheduled Maintenance</h1>
              <p className='message'>
                We are undergoing scheduled maintenance. The app will be available Feb. 1, 2025 at
                11:00 PM PT.
              </p>
              <a className='cc-link' href='https://clearcapital.com'>
                Go to clearcapital.com
              </a>
            </div>
          </div>
        </Route>
      </Switch>
      <Footer />
      <ModalsSystem />
    </ScheduledMaintenance.Styled>
  )
}

ScheduledMaintenance.Styled = styled.div<{headerHeight?: number}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: ${({headerHeight}) => headerHeight ?? 0}px;
  overflow-x: hidden;
  padding-top: 60px;

  .maintenance-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 360px;
    padding: 0 40px;
    justify-content: center;
    align-items: flex-start;
    background: url(${HeroBackgroundCCLogo}) no-repeat right,
      linear-gradient(179.29deg, rgba(255, 213, 102, 0.8) 4.76%, rgba(255, 194, 32, 0.8) 161.07%),
      linear-gradient(
        0deg,
        ${({theme}) => theme.colors.grayscale.white},
        ${({theme}) => theme.colors.grayscale.white}
      );

    .content {
      width: 370px;

      h1.title {
        font-size: 2rem;
      }

      p.message {
        font-size: 1rem;
      }

      a.cc-link {
        font-weight: 500;
        text-decoration: none;

        &:visited,
        :hover,
        :active {
          color: inherit;
        }
      }
    }
  }
`

export default ScheduledMaintenance
